@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: 'Cairo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.scrollbar-hidden {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE and Edge */
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

/* .max-container{
  max-width:1440px;
  margin:0 auto;
} */
.max-container{
  @apply max-w-[1280px] mx-auto
}

@media (min-width: 768px) {
  .md\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
      --tw-space-x-reverse: 0 !important;
      margin-right: 0 !important;
      margin-left: 0 !important;
  }
}

.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top:  0 !important;
  margin-bottom: 0 !important;
}
